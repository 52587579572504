import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import ReactMarkdown from 'react-markdown'

const Posts = () => {
  const api = 'https://blog.claudflyer.com/api'
  const [post, setPosts] = useState([]);
  useEffect(() => {
    const getPosts = async () => {
      const r = await fetch(`${api}/post`);
      const posts = await r.json()
      const res = await fetch(`${api}/user`);
      const authors = await res.json()

      for(const p of posts) {
        if(p.authors) {
          for(const i in p.authors) {
            p.authors[i] = authors.find(a => a.id == p.authors[i])
          }
        }
      }
      setPosts(posts);
    };
    getPosts();
  }, []);

  return (
    <div>
      <div className="row">
        {post.map((post) => (
          <div key={post.id} className="col-md-4">
            <div className="card mb-4">
              <div className="card-body">
                <h2><Link to={`/post/${post.id}`}>{post.title}</Link></h2>
                <div className="small mb-1">{new Date(post.modified).toLocaleString()} by {post.authors.map((author, index) => (<span>{index > 0 && index !== post.authors.length - 1 && ', '}{index > 0 && index === post.authors.length - 1 && ' and '}<Link to={`/user/${author.id}`}>{author.name}</Link></span>))}</div>
                <ReactMarkdown  linkTarget="_blank" className="mt-3 mb-3">{post.tagline}</ReactMarkdown>
                {post.tags.map((tag) => (<a key={tag} href={"/tag/" + tag.trim().toLowerCase().replace(/\s+/g, "-")} className="badge badge-sm badge-primary mr-1">{tag}</a>))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Posts;