import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import ReactMarkdown from 'react-markdown';

const Post = ({ id }) => {
  const api = 'https://blog.claudflyer.com/api'

  const [post, setPost] = useState({});
  const [authors, setAuthors] = useState([]);
  useEffect(() => {
    const getPost = async () => {
      const r = await fetch(`${api}/post/${id}`);
      const p = await r.json()
      setPost(p);
      if(p.authors)
      {
        let a = []
        const res = await fetch(`${api}/user`);
        const authors = await res.json()
        
        for(const i of p.authors) {
          a.push(authors.find(a => a.id == i))
        }
        setAuthors(a)
      }
      else
      {
        setAuthors([{name: 'Unknown'}])
      }
    };
    getPost();
  }, [id]);

  if (!Object.keys(post).length) return <div />;

  return (
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10 card card-body">
        <h1>{post.title}</h1>
        <div className="small mb-3">Published {new Date(post.modified).toLocaleString()} by {authors.map((author, index) => (<span>{index > 0 && index !== authors.length - 1 && ', '}{index > 0 && index === authors.length - 1 && ' and '}<Link to={`/user/${author.id}`}>{author.name}</Link></span>))}</div>
        <ReactMarkdown linkTarget="_blank">{post.body}</ReactMarkdown>
        <div>
        {post.tags.map((tag) => (<a key={tag} href={"/tag/" + tag.trim().toLowerCase().replace(/\s+/g, "-")} className="badge badge-sm badge-primary mt-3 mr-1">{tag}</a>))}
        </div>
      </div>
      <div className="col-md-1"></div>
    </div>
  );
};

export default Post;