import { Router } from '@reach/router'
import Posts from './components/posts'
import Post from './components/post'
import User from './components/user'
import './App.css';

const NotFound = () => (
  <div className="row">
    <div className="col-md-1"></div>
    <div className="col-md-10 card card-body border-secondary text-center">
      <h3><i class="fas fa-dumpster-fire"></i>&nbsp;Oops! There is nothing here yet.</h3>Check your spelling and try again
    </div>
    <div className="col-md-1"></div>
  </div>
  );

function App() {
  return (
    <Router>
      <Posts path="/" />
      <Post path="/post/:id" />
      <User path="/user/:id" />
      <Posts path="/manage/post" />
      <Post path="/manage/post/:id" />
      <NotFound default />
    </Router>
  );
}

export default App;