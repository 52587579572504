import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";
import ReactMarkdown from 'react-markdown';

const Post = ({ id }) => {
  const api = 'https://blog.claudflyer.com/api'

  const [user, setUser] = useState([]);
  useEffect(() => {
    const getUser = async () => {
      const r = await fetch(`${api}/user/${id}`);
      const p = await r.json()
      setUser(p);
    };
    getUser();
  }, [id]);

  if (!Object.keys(user).length) return <div />;

  return (
    <div className="row">
      <div className="col-md-1"></div>
      <div className="col-md-10">
        <h1>{user.name}</h1>
        <div className="small mb-3">{user.title} - <a href={"mailto:" + user.email}>{user.email}</a></div>
        <ReactMarkdown className="mb-3">{user.description}</ReactMarkdown>
        {user.tags.map((tag) => (<span className="badge badge-sm badge-primary mr-1">{tag}</span>))}
        <p>&nbsp;</p>
        <p><Link to="/">Go back</Link></p>
      </div>
      <div className="col-md-1"></div>
    </div>
  );
};

export default Post;